<template>
    <div class="fake-picture" >
		
	</div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
    }
  },
  mounted() {   
      
  },
  computed: {

  },
  created() {
    
  },
  methods: {
	  
  }
}
</script>

<style lang="scss">
.fake-picture{
  // height: 1000px;
  position:fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
   margin: -20px;
  // background: rgb(106, 107, 114) url("../../assets/img/client/home.jpg") no-repeat;
  background-size:100% 100%;
  background-attachment:fixed;
  background-size: cover;
}
.willExpireDialog{
  .el-dialog__body{
    min-height: 0;
  }
}
.mod-home {
    font-family: "微软雅黑" !important;
  .dialog-box {
    position: relative;
    z-index: 2000;
    .el-dialog__body {
      min-height: 50px;
    }
  }

  .home-dialog-mask-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1999;
    overflow: auto;
    margin: 0;
    .mask {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .5;
      background: #000;
    }
  }
table {
  border: 1px solid rgba(225, 225, 227, 1);
  border-bottom: none;
  border-right: none;
  font-size: 12px;
  color: #212b36;
  line-height: 12px;
  width: 100%;

  th {
    height: 28px;
    border-right: 1px solid rgba(225, 225, 227, 1);
    border-bottom: 1px solid rgba(225, 225, 227, 1);
    padding: 0;
    margin: 0;
    font-weight: 400;
    &.title {
      background: #f4f6f8;
      color: #9b9ca3;
      font-weight: 500;
    }
  }

  .rank {
    color: #0068b7;
  }

  .number {
    color: #faad14;
    font-size: 16px;
  }
}
}

</style>
